import axios from 'axios'

import { getCurrentLocale, getNodeENV, HostEnv, Locale } from '@/locale'
import { isObject } from '@/type.util'
import { sentry } from '@/utils/sentry'

const localeWithApi: Record<Locale, string> = {
  [Locale.TW]: 'tw',
  [Locale.JP]: 'jp',
  [Locale.TH]: 'th',
  [Locale.ASIA]: 'asia',
  [Locale.IO]: 'io',
  [Locale.EU]: 'eu',
  [Locale.UK]: 'uk',
  [Locale.FR]: 'fr',
  [Locale.DE]: 'de',
  [Locale.ES]: 'es',
  [Locale.KR]: 'kr',
}
const prodDomain = 'https://antler.rhinoshield.app'
const stagDomain = 'https://antler.evo-stag.net'

let productStatusSet: null | Set<string> = null
export async function getProductPendingSkus(): Promise<null | Set<string>> {
  if (productStatusSet !== null) {
    return productStatusSet
  }
  try {
    const locale = localeWithApi[getCurrentLocale()]
    const domain = getNodeENV() === HostEnv.Production ? prodDomain : stagDomain
    const result = await axios.get(`${domain}/api/merchandise-status/${locale}`, {
      params: {
        status: 'pending',
      },
    })
    if (!isObject(result.data) || !('data' in result.data)) {
      return null
    }
    productStatusSet = new Set(checkTyping(result.data.data).map((item) => item.sku))
    return productStatusSet
  } catch (error) {
    sentry.error('product pending status', {
      error,
    })
    return null
  }
}

interface ProductStatusWithPending {
  sku: string
  status: 'pending'
}
function isProductStatusWithPending(obj: unknown): obj is ProductStatusWithPending {
  return (
    isObject(obj) &&
    'sku' in obj &&
    typeof obj.sku === 'string' &&
    'status' in obj &&
    obj.status === 'pending'
  )
}

function checkTyping(arr: unknown): ProductStatusWithPending[] {
  if (!Array.isArray(arr)) {
    return []
  }
  return arr.filter((item: unknown): item is ProductStatusWithPending =>
    isProductStatusWithPending(item),
  )
}
