import { t } from 'i18next'

import { Font } from '@/materials/fonts.config'
import { isArrayOf, isObject, type PositionedImage } from '@/type.util'

// 放大設計圖提升 pdf dpi 超過最低限度的 300
export const FORCE_ENHANCE_DPI_SCALE = 1.5
export const CASE_MATERIAL_IMAGE_SIZE = 2000

export interface PhotoInfo {
  blobUrl: string
  cdnUrl: string
  cuttingHeight: number
  cuttingOffsetX: number
  cuttingOffsetY: number
  cuttingWidth: number
  editable: boolean
  height: number
  id: string
  name: string
  rotation: number
  visibility: boolean
  width: number
  xOffset: number
  yOffset: number
  zIndex: number
}

export class Design {
  public photos: PhotoInfo[] = []
  public texts: TextInfo[] = []
}

export class Photo implements PhotoInfo {
  public blobUrl!: string
  public cdnUrl!: string
  public cuttingHeight!: number
  public cuttingOffsetX = 0
  public cuttingOffsetY = 0
  public cuttingWidth!: number
  public editable = true
  public height!: number
  public id!: string
  public name!: string
  public rotation = 0
  public visibility = true
  public width!: number
  public xOffset = 0
  public yOffset = 0
  public zIndex = 0
  public constructor(
    blobUrl: string,
    cdnUrl: string,
    name: string,
    width: number,
    height: number,
    x = 0,
    y = 0,
    rotation = 0,
    zIndex = 0,
  ) {
    this.id = getRandomString()
    this.blobUrl = blobUrl
    this.cdnUrl = cdnUrl
    this.name = name
    this.width = this.cuttingWidth = width
    this.height = this.cuttingHeight = height
    this.xOffset = x
    this.yOffset = y
    this.rotation = rotation
    this.zIndex = zIndex
  }

  public static clone(target: PhotoInfo): PhotoInfo {
    const clonePhoto = { ...target }
    clonePhoto.id = getRandomString()
    clonePhoto.xOffset += 50
    clonePhoto.yOffset += 50
    clonePhoto.zIndex++
    return clonePhoto
  }
}
export function getRandomString(): string {
  return Math.random().toString(36).slice(2)
}

export function isPhotoInfo(x: unknown): x is PhotoInfo {
  return (
    isObject(x) &&
    typeof x.id === 'string' &&
    typeof x.blobUrl === 'string' &&
    typeof x.cdnUrl === 'string' &&
    typeof x.name === 'string' &&
    typeof x.xOffset === 'number' &&
    typeof x.yOffset === 'number' &&
    typeof x.width === 'number' &&
    typeof x.height === 'number' &&
    typeof x.rotation === 'number' &&
    typeof x.cuttingWidth === 'number' &&
    typeof x.cuttingHeight === 'number' &&
    typeof x.cuttingOffsetX === 'number' &&
    typeof x.cuttingOffsetY === 'number' &&
    typeof x.zIndex === 'number' &&
    typeof x.visibility === 'boolean' &&
    typeof x.editable === 'boolean'
  )
}
export function isPhotoInfoArray(photos: unknown): photos is PhotoInfo[] {
  return isArrayOf(photos, isPhotoInfo)
}

export interface TextInfo {
  background: string
  baseLineOffset: number
  borderRadius: number
  content: string
  editable: boolean
  fontColor: string
  fontFamily: string
  fontSize: number
  height: number
  id: string
  rotation: number
  visibility: boolean
  width: number
  xOffset: number
  yOffset: number
  zIndex: number
}
export class Text implements TextInfo {
  public background = 'transparent'
  public baseLineOffset = 0
  public borderRadius = 0
  public content = t('message.input_text')
  public editable = true
  public fontColor = '#FFFFFF'
  public fontFamily = Font.NotoSansCJKtcBlack
  public fontSize = 14
  public height = 28
  public id!: string
  public rotation = 0
  public visibility = true
  public width = 120
  public xOffset = CASE_MATERIAL_IMAGE_SIZE / 2
  public yOffset = CASE_MATERIAL_IMAGE_SIZE / 2
  public zIndex = 0

  public constructor(zIndex = 0) {
    this.id = getRandomString()
    // 新增文字的時候加入亂數偏移
    this.xOffset = CASE_MATERIAL_IMAGE_SIZE / 2 + getRandomArbitrary(-100, 100)
    this.yOffset = CASE_MATERIAL_IMAGE_SIZE / 2 + getRandomArbitrary(-100, 100)
    this.zIndex = zIndex
  }

  public static clone(target: TextInfo): TextInfo {
    const cloneText = { ...target }
    cloneText.id = getRandomString()
    cloneText.xOffset += 50
    cloneText.yOffset += 50
    cloneText.zIndex++
    return cloneText
  }
}

export function isTextInfo(x: unknown): x is TextInfo {
  return (
    isObject(x) &&
    typeof x.id === 'string' &&
    typeof x.xOffset === 'number' &&
    typeof x.yOffset === 'number' &&
    typeof x.width === 'number' &&
    typeof x.height === 'number' &&
    typeof x.fontSize === 'number' &&
    typeof x.fontColor === 'string' &&
    typeof x.background === 'string' &&
    typeof x.borderRadius === 'number' &&
    typeof x.content === 'string' &&
    typeof x.zIndex === 'number' &&
    typeof x.visibility === 'boolean' &&
    typeof x.editable === 'boolean' &&
    typeof x.fontFamily === 'string' &&
    typeof x.baseLineOffset === 'number'
  )
}

export function isTextInfoArray(texts: unknown): texts is TextInfo[] {
  return isArrayOf(texts, isTextInfo)
}

export function getRandomArbitrary(min: number, max: number): number {
  return Math.random() * (max - min) + min
}

// 要計算一行可以放幾個元素，但元素中間會有間距
// 這邊我不知道怎麼算比較好，只好用迴圈算 XD，如果有比較好的算法最好改掉
export function calculateMaxRowNumber(
  containerWidth: number,
  clientWidth: number,
  spacing: number,
): number {
  const maxRowNumber = Math.floor(containerWidth / clientWidth)
  let realRowNumber = 0
  for (let i = maxRowNumber; i >= 0; i--) {
    const expectWidth = clientWidth * i + spacing * (i - 1)
    if (containerWidth >= expectWidth) {
      realRowNumber = i
      break
    }
  }
  return realRowNumber
}

export function toFullSizeImage(url: string): PositionedImage {
  return {
    url,
    rect: { x: 0, y: 0, width: CASE_MATERIAL_IMAGE_SIZE, height: CASE_MATERIAL_IMAGE_SIZE },
  }
}
